'use client'
import React from 'react'
import { createEmotionCache, MantineProvider } from '@mantine/core'
import { useServerInsertedHTML } from 'next/navigation'

// must be created outside of the component to persist across renders
const cache = createEmotionCache({ key: 'dp', prepend: false })
cache.compat = true

type Props = {
  children: React.ReactNode
}

export default function MantineStyleRegistry(props: Props) {
  const { children } = props
  // const { theme, setTheme } = useTheme()
  // const [theme_, setTheme_] = useState<string>('');
  //
  //
  // useEffect(() => {
  //     let localTheme = localStorage.getItem('items') as string;
  //     setTheme_(localTheme)
  // }, [setTheme_, theme_,theme]);

  //
  // console.log(theme)

  useServerInsertedHTML(() => (
    <style
      data-emotion={`${cache.key} ${Object.keys(cache.inserted).join(' ')}`}
      dangerouslySetInnerHTML={{
        __html: Object.values(cache.inserted).join(' '),
      }}
    />
  ))
  // if (typeof window !== 'undefined') return <>{children}</>
  return (
    // <CacheProvider value={cache}>
    <MantineProvider
      //     theme={{
      //   globalStyles: (theme) => ({
      //     '*, *::before, *::after': {
      //       boxSizing: 'border-box',
      //     },
      //
      //     body: {
      //       ...theme.fn.fontStyles(),
      //       backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
      //       color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
      //       lineHeight: theme.lineHeight,
      //     },
      //     input: {
      //       ...theme.fn.fontStyles(),
      //       fontSize: 128
      //     },
      //   }),
      // }}
      // withGlobalStyles
      // withNormalizeCSS
      emotionCache={cache}
    >
      {/*<Global*/}
      {/*    styles={(theme) => ({*/}
      {/*      input: {*/}
      {/*        ...theme.fn.fontStyles(),*/}
      {/*      fontSize: 128*/}
      {/*      },*/}
      {/*    })}*/}
      {/*/>*/}
      {children}
    </MantineProvider>
    // </CacheProvider>
  )
}
